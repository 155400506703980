.App {
	text-align: center;
}

body {
	background-color: #f5f5dc;
	cursor: none;
	font-family: monospace, sans-serif;
	letter-spacing: 0.2px;
}

@font-face {
	font-family: "comicStuff";
	src: url("./js/BADABB__.TTF") format("truetype");
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}
.navbar {
	text-align: center;
	width: 50%;
	background-color: transparent;
	height: 70px;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding-bottom: 0px;
	padding: 10px;
	margin-right: auto;
	margin-left: auto;
	transition-duration: 0.5s;
}

.navbar:hover {
	transition-property: all;
	text-align: center;
	background-color: #d52429;
	box-shadow: 0 0 50px 10px #797671;
}

.navbar li {
	list-style-type: none;
	display: inline;
}

.navbar li a {
	display: block;
	font-size: 1.3rem;
	color: #f1602c;
	font-weight: 600;
	text-decoration: none;
	position: relative;
	cursor: none;
	overflow: hidden;
}
.navbar li a:after {
	content: "";
	position: absolute;
	width: 100%;
	transform: scaleX(0);
	height: 3px;
	left: 0;
	font-weight: 600;

	bottom: 0;
	background-color: #ec8922;
	transform-origin: bottom right;
	transition: transform 0.25s ease-out;
}

.navbar li a:hover:after {
	transform: scaleX(1);
	transform-origin: bottom left;
}
.ramallah .red {
	color: red;
}
.ramallah .green {
	color: green;
}
.ramallah .white {
	color: white;
}
.ramallah .black {
	color: black;
}

.navbar:hover li a {
	color: #f5f5dc;
}

@media only screen and (max-width: 920px) {
	.navbar li {
		display: inline;
	}
}

@media only screen and (max-width: 920px) {
	.navbar {
		width: 100%;
	}

	.cursor {
		visibility: hidden;
	}
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.container {
	background-color: #f5f5dc;
	text-align: center;
	color: #f9f6f1;
}

.cursor {
	position: fixed;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: black;
	transform: translate(-50%, -50%);
	pointer-events: none;
}

.Name {
	text-decoration: overline;
	text-decoration-thickness: 1px;
	font-family: monospace, sans-serif;

	font-size: 80px;
	display: block;
	color: #6c3f18;
	font-weight: 800;
}

.skill {
	margin-top: 0px;
}

.list {
	width: 80%;
	margin-right: auto;
	margin-left: auto;
	text-align: left;
	margin-top: 5px;
	display: grid;
	grid-auto-flow: column;
}

.hhh {
	margin-right: 50px;
}

.vl {
	border-left: 2px solid #f1602c;
	padding-left: 20px;
}

.list h3 {
}

.bubble {
	background-color: #212121;
	border: none;
	color: #f9f6f1;
	padding: 8px 20px;
	font-weight: 600;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	margin: 4px 2px;

	cursor: pointer;
	border-radius: 16px;
}

.first {
	transition: 3s;
}

.first:hover {
	color: #f1602c;
	-webkit-transition: all 3s;
	transition: all 0.3s;
}

.last {
	transition: 3s;
}

.last:hover {
	color: #f1602c;
	cursor: none;
	transition-duration: 200ms;
	transition-property: all;
	transform: rotateX(15deg);
}

.home {
	padding-top: 230px;
	width: 100%;
	color: #6c3f18;
}
@media only screen and (max-width: 920px) {
	.home {
		padding-top: 130px;
		width: 100%;
		display: grid;
		grid-auto-flow: row;
		margin-left: auto;
		margin-right: auto;
	}
	.Name {
		font-size: 60px;
	}
	.text {
		margin-top: 25px;
	}

	.list {
		grid-auto-flow: row;
	}
}

.about {
	margin-top: 80px;
	justify-content: center;
}

.text {
	margin-top: 25px;
}

.line-5 {
	height: 5px;
	margin-bottom: 40px;
	background: linear-gradient(
		to bottom,
		transparent 2px,
		#f1602c 2px,
		#f1602c 3px,
		transparent 3px
	);
}

.parag {
	font-size: 20px;
	font-weight: 550;
	vertical-align: middle;
	text-align: left;
	line-height: 30px;
	color: #212121;
}

.error {
	margin-top: 130px;
	color: #f1602c;
}

.projects {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	margin-top: 38px;
	grid-gap: 20px;
}

@media only screen and (max-width: 920px) {
	.projects {
		grid-template-columns: 1fr;
		padding-left: 0;
		margin-left: 0px;
	}
}

.proj {
	background-color: #ec8922;
	color: #d52429;
	transition-duration: 0.5s;
	text-align: left;
}

.cardDetails {
	border: 1px solid rgb(227, 207, 176);
	background-color: white;
}

.rootly-logo {
	width: 200px;
	height: auto;
}

@media (max-width: 768px) {
	.rootly-logo {
		width: 150px; 
	}
}

@media (max-width: 480px) {
	.rootly-logo {
		width: 100px;
	}
}


.scramble-logo {
	width: 250px;
	height: auto;
}

@media (max-width: 768px) {
	.scramble-logo {
		width: 200px; 
	}
}

@media (max-width: 480px) {
	.scramble-logo {
		width: 190px;
	}
}

.cardDetails .screenShot {
}
.cardDetails.secondary {
	border: 1px solid rgb(227, 207, 176);
	background-color: #212121;
	color: white;
}

.bg-view {
	background-color: transparent;
	border: 1px solid #212121;
	color: black;
}

.bg-view.secondary {
	background-color: transparent;
	border: 1px solid white;
	color: white;
}
.bg-view.secondary:hover {
	color: white;
	background-color: #d52429;
	border: none;
	cursor: none;
}

.bg-view:hover {
	color: white;
	background-color: #d52429;
	border: none;

	cursor: none;
}

.bg-javascript {
	background-color: #d9c10c;
}

.bg-pyramid {
	background-color: orange;
}

.bg-neo4j {
	background-color: #212121;
}

.proj-secondary {
	background-color: #6c3f18;
	color: white;
}

.proj h1 {
	font-size: 45px;
	text-align: left;
	letter-spacing: -1px;
}

.projDate {
	font-size: 15px;
	background-color: #d52429;
	color: white;
	font-weight: 600;
	position: absolute;
	bottom: 4%;
	right: 2%;
	padding: 0px 6px;
	border-radius: 3px;
}
.proj:hover {
	background-color: #f1602c;
}

.bzu {
	font-size: 20px;
	color: green;
	font-weight: 600;
	text-decoration: none;
	overflow: hidden;
	transition-duration: 0.2s;
}

.bzu:hover {
	text-decoration: underline;
}

.hi {
	font-size: 60px;
	color: #212121;
}

.hi {
	transition: 3s;
}

.hi:hover {
	color: #f1602c;
	-webkit-transition: all 3s;
	transition: all 0.3s;
}

.bord {
	height: 450px;
	background-color: #212121;
	position: relative;
}
.pic {
	top: -55px;
	left: -15px;
	cursor: crosshair;
	z-index: 2;
	height: 30rem;
	position: absolute;
}

@media only screen and (max-width: 1400px) {
	.pic {
		height: 26rem;
		top: -10px;
	}
}
@media only screen and (max-width: 1200px) {
	.pic {
		height: 22rem;
		top: -10px;
	}
	.bord {
		height: 360px;
		background-color: #212121;
		position: relative;
	}
}

@media only screen and (max-width: 1000px) {
	.about {
		display: grid;
		grid-template-columns: 1fr;
		margin-top: 5%;
	}
	.pic {
		height: 300px;
		top: 0%;
		left: 0;
	}
	.bord {
		margin-left: auto;
		margin-right: auto;
		width: 400px;
		height: 340px;
		background-color: #212121;
	}
	.parag {
		text-align: center;
	}
}

.contact {
	margin-top: 30px;
	color: #212121;
}

.prob {
	color: #f1602c;
	letter-spacing: 1px;
	text-decoration: overline;
	text-decoration-thickness: 1px;
	text-decoration-color: #f1602c;
}

.dec {
	opacity: 70%;
}

.grid {
	margin-top: 60px;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 30px;
}

.social {
	background-color: #6c3f18;
	width: 50%;
	height: 90px;
	margin-left: auto;
	margin-right: auto;
	text-align: left;
	transition-duration: 0.5s;
	position: relative;
	overflow: hidden;
}

.social button {
	visibility: hidden;
	position: absolute;
	background-color: #212121;
	border: none;
	font-family: monospace;
	font-size: large;
	bottom: 10%;
	left: 33%;
	right: 33%;
	font-weight: 800;
}

.social button:hover {
	background-color: #f1602c;
	color: #212121;
	border: 3px solid #212121;
}

.social h1 {
	font-size: 80px;
	background-color: transparent;
	position: absolute;
	font-weight: 800;
	left: 2%;
	letter-spacing: -3px;
}

.social:hover {
	color: black;
	height: 190px;
	background-color: #f1602c;
}
.social:hover h1 {
	font-weight: 800;
	color: black;
}

.social:hover button {
	transition-duration: 0.3s;
	visibility: visible;
}
.icon {
	height: 90px;
	float: right;
	position: absolute;
	right: 4%;
	color: #f9f6f1;
	filter: invert(1);
	transition-duration: 0.5s;
}

.social:hover .icon {
	filter: invert(0);
}

.linkedIn {
	transition: 3s;
}

.linkedIn:hover {
	color: rgb(53, 53, 213);
	-webkit-transition: all 3s;
	transition: all 0.3s;
}

@media only screen and (max-width: 920px) {
	.social {
		width: 100%;
	}
	.icon {
		right: 0%;
		height: 70px;
		top: 10%;
	}
	.social h1 {
		left: 0%;
	}
}

.gitHub {
	transition: 3s;
}

.gitHub:hover {
	color: grey;
	-webkit-transition: all 3s;
	transition: all 0.3s;
}

.gmail {
	transition: 3s;
}

.gmail:hover {
	color: yellow;
	-webkit-transition: all 3s;
	transition: all 0.3s;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
